<script>
import { authMethods } from '@state/helpers'
import AuthPageCard from "@components/auth/AuthPageCard";
export default {
  components: {AuthPageCard},
  page: {
    title: 'Υπενθύμιση κωδικού',
    meta: [{ name: 'description', content: `Υπενθύμινση κωδικού στο athlisis` }],
  },
  data() {
    return {
      email: '',
      error: null,
      tryingToReset: false,
      isResetError: false,
      isSuccess: false,
      successMessage: null
    }
  },
  computed: {
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.tryingToReset = true;
      // Reset the authError if it existed.
      this.error = null;
      return this.resetPassword({
        email: this.email
      })
        .then((data) => {
          this.tryingToReset = false;
          this.isResetError = false;
          this.isSuccess = true;
          this.successMessage = 'Σας έχει σταλεί ένα e-mail επαναφοράς του κωδικού σας!'
        })
        .catch((error) => {
          this.tryingToReset = false;
          this.error = error? error.response.data.message: "";
          this.isResetError = true;
          this.isSuccess = false;
        })
    },
  },
}
</script>

<template>

  <div id="forgot-password">
    <auth-page-card subtitle="Εισάγετε την ηλεκτρονική σας διεύθυνση και θα σας στείλουμε ένα e-mail με οδηγίες επαναφοράς του κωδικού σας.">

      <template v-slot:default>
        <div>
          <b-alert v-model="isResetError" variant="danger">
            {{error}}
          </b-alert>

          <b-alert v-model="isSuccess" variant="success">
            {{successMessage}}
          </b-alert>


          <b-form @submit.prevent="tryToReset">
            <b-form-group id="email-group" label="Email" label-for="email">

              <b-form-input id="email" v-model="email" type="email" required placeholder="Εισάγετε το e-mail σας" :disabled="isSuccess"></b-form-input>
            </b-form-group>

            <b-form-group id="button-group" class="mt-4">
              <b-button v-show="!isSuccess" type="submit" variant="primary" class="btn-block"> Επαναφορά κωδικού </b-button>
            </b-form-group>
          </b-form>
        </div>
      </template>

      <template v-slot:under-card>
        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-white-50">Έχετε ήδη έναν λογαριασμό;
              <router-link tag="a" to="/login" class="text-white ml-1"><b>Συνδεθείτε τώρα</b></router-link>
            </p>
          </div>
        </div>
      </template>
    </auth-page-card>
  </div>
</template>

<style lang="scss" module></style>
